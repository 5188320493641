// Set up remote config
import * as configcat from "configcat-js";


// Seeing all log messages makes the first integration easier. When the integration is done you can remove this line to avoid too detailed logging in your application.
let logger = configcat.createConsoleLogger(3); // Setting log level to 3 (Info)

let configCatClient = configcat.createClient(process.env.VUE_APP_ConfigCatKey, {
    logger: logger
});

async function init (user) {

  return new Promise (async (resolve, reject ) => {

    try {
      let userObject = {
        identifier: user.uid,
        custom: {
          "UserRole": user.isAdmin ? "Admin" : "normal",
        }
      };
      
      const settingValuesTargeting = await configCatClient.getAllValuesAsync(userObject);
    
      let obj = {}
    
      settingValuesTargeting.forEach(i => {
        obj[i.settingKey] = i.settingValue
      });
      
      console.log('obj:', obj);
      
      resolve(obj)
      
    } catch (error) {
      console.log("err getting remote config:", error)
      reject(null)
    }

  }) 
  
}

export default {
  init
}