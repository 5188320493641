import { render, staticRenderFns } from "./modal-confirm_generic.html?vue&type=template&id=5a87b47f&scoped=true&"
import script from "./modal-confirm_generic.js?vue&type=script&lang=js&"
export * from "./modal-confirm_generic.js?vue&type=script&lang=js&"
import style0 from "./modal-confirm_generic.scss?vue&type=style&index=0&id=5a87b47f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a87b47f",
  null
  
)

export default component.exports