<template>
	<div class="container mx-auto pt-10 text-center">

		<div v-if="!accountsChecked" class="flex h-100 items-center flex-col sm:flex-row">
			<Loader />
		</div>

		<div v-else>
			<!-- Accounts have been checked -->

			<div v-if="accounts.length">
				<router-link v-for="account in accounts" :key="account.id" :to="account.id" class="bg-main w-full flex items-center justify-start max-w-sm mx-auto py-3 px-4 mt-4">
					<img v-if="account.icon" :src="account.icon.src" alt="account icon" width="30" height="30" class="bg-white rounded-full mr-2">
					{{account.name}}
				</router-link>
				<p class="mt-5">Add a new site</p>
			</div>

			<div v-else>
				<!-- user does not have access to any sites -->
				<p>What's your site's URL?</p>
			</div>

			<div>
				<form 
					id="domainForm"
					@submit.prevent="submitDomainForm" 
					class="text-center">
						<input
								id="domain"
								v-model="newDomain"
								type="text"
								name="domain"
								class="w-full md:max-w-xs appearance-none mb-3 block m-auto"
								placeholder="awesomesite.com"
								v-debounce:300ms="getFavicon"
								:debounce-events="['keyup']"
						>
						<button 
							class="primary py-2 block" 
							:class="[{'cursor-not-allowed': !validDomain}, {'opacity-50': !validDomain}]" 
							type="submit" 
							:disabled="!validDomain"
							v-if="!view.domainFormLoading">
							Add
						</button>
						<span v-if="view.domainFormLoading">
							<Loader />
						</span>
				</form>
			</div>

		</div>
		
	</div>
</template>

<script>
import { db } from '@/main'
import firebase from 'firebase/app'
export default {
	name: 'Home',
	data: function () {
		return {
			view: {
				domainFormLoading: false,
				isAdmin: false
			},
			newDomain: "",
			siteIcon: null
		}
	},
	computed: {
		validDomain () {
			// returns true or false based on whether the inputted domain is valid or not
			return !!this.newDomain.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/)
		},
		user () {
			return this.$store.state.user
		},
		accounts () {
			return this.$store.state.accounts
		},
		accountsChecked () {
			return this.$store.state.accountsChecked
		}
	},
	mounted: async function(){
		console.log("home mounted")
		if(!this.user){
			this.$router.push('/login')
		} else {

			if(!this.accounts.length){
				await this.$store.dispatch("getAccounts", this.user)	
			} else {
				console.log('this.accounts:', this.accounts);
			}

			// check if we have a site in the URL query params
			if(this.$route.query.site) {
				this.newDomain = this.$route.query.site
			}
		}

	},
	methods: {
		async getFavicon () {
			
			if(!this.validDomain) return 

			console.log("getting favicon")

			let icon = null

			try {
				
				// https://favicongrabber.com/api/grab/thebrokebackpacker.com
				let request = await fetch(`https://favicongrabber.com/api/grab/${this.newDomain}`)
	
				let res = await request.json()
				
				// sort them by resolution
				res.icons.sort((a, b) => {
	
					let sizea = a.sizes ? Number(a.sizes.split("x")[0]) : 0
					let sizeb = b.sizes ? Number(b.sizes.split("x")[0]) : 0
	
					return sizeb - sizea
				})
	
				console.log('res.icons AFTER:', res.icons);
				icon = res.icons[0]
			} catch (error) {
				console.log("error getting favicon")
			} finally {
				this.siteIcon = icon
			}

		},
		async submitDomainForm (){

			console.log('checking domain input form')

			this.view.domainFormLoading = true

			// account document data
			let newAccount = {
				name: encodeURIComponent(this.newDomain),
				hwCamref: "",
				teammates: [this.user.uid],
				teammateInvites: [],
				owner: this.user.uid,
				archived: false,
				icon: this.siteIcon,
				onboarding: true,
				created: new Date().getTime()
			}

			// we need to create the secure account too
			let newSecureAccount = {
				name: encodeURIComponent(this.newDomain),
				plan: "Starter",
				teammates: [this.user.uid],
				owner: this.user.uid,
				archived: false
			}

			// Get a new write batch
			var batch = db.batch();

			let accountRef = db.collection("accounts").doc(encodeURIComponent(this.newDomain.toLowerCase()))
			let secureAccountRef = db.collection("accountsSecure").doc(accountRef.id)
			// let widgetsRef = accountRef.collection('hwWidgets').doc()

			batch.set(accountRef, newAccount);
			batch.set(secureAccountRef, newSecureAccount )

			// Commit the batch
			try {
				await batch.commit()
				this.newDomain = ""

				// refresh the accounts
				this.$store.dispatch("getAccounts", this.user);
				this.$router.push(`/${accountRef.id}`)
			} catch(e) {
				console.log("error batch writing:", e)
				this.$toast.error("Damn, something went wrong...")
			} finally {
				this.view.domainFormLoading = false
			}

		}
	}
}

</script>

<style scoped>
	
</style>