import firebase from 'firebase/app'
import 'firebase/auth'
import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations,firestoreAction } from 'vuexfire'
import {db} from './main'
import helpers from '@/helpers.js'
import remoteConfig from '@/remote-config.js'
import { unionBy } from "lodash"
import { startOfMinute } from 'date-fns'

let googleProvider = new firebase.auth.GoogleAuthProvider();

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		user: null,
		users: [], // for Admin/users view
		userData: null,
  	authChecked: false,
		accountsChecked: false,
		selectedAccount: null,
		selectedAccountSecure: null,
		accounts: [],
		widgets: {
			hwSearch: [],
			hwWidgets: [],
			hwPowered: [],
			hwMap: []
		},
		directories: {
			widgetDirectory: {
				hwWidgets: {},
				hwSearch: {},
				hwMap: {}
			}
		},
		escPressed: null,
		remoteUserConfig: {
			// for future reference, you're setting up configcat for remote user config!
		}
  },
  mutations: {
  	setUser (state, payload){
  		state.user = payload;
  	},
		setUsers (state, payload) {
			state.users = payload;
		},
  	setAuthChecked (state, payload){
  		state.authChecked = payload;
  	},
  	setAccounts(state, payload){
  		state.accounts = payload;
  		state.accountsChecked = true;
		},
		setSecureAccounts(state, payload){
			console.log('setting Secure accounts:', payload)
			state.secureAccounts = payload;
		},
		setSelectedAccount(state, payload){
			state.selectedAccount = payload
		},
		setWidgets(state, payload){
			state.widgets[payload.type] = payload.docs
		},
		clearWidgets(state){
			state.widgets = {
				hwSearch: [],
				hwMap: [],
				hwWidgets: [],
				hwPowered: []
			}
		},
		setDirectories(state, payload){
			state.directories = payload
		},
		setEscPressed(state, payload){
			state.escPressed = payload
		},
		setRemoteUserConfig(state, payload){
			console.log('setting remote userConfig:', payload);
			
			state.remoteUserConfig = payload || {} // handle if payload is null, in event of error
		},
		...vuexfireMutations
  },
  actions: {
		updateEscPressed ({commit}) {
			console.log("ESC pressed")
			commit('setEscPressed', new Date().getTime())
		},
		bindUserData: firestoreAction(async ({ bindFirestoreRef }, uid) => {
				// return the promise returned by `bindFirestoreRef`
				// console.log('bindUserData with id:', uid)
				try {
					let bound = await bindFirestoreRef('userData', db.doc(`users/${uid}`));
					return bound
				} catch(e){
					console.log('binding error:', e)
					// try again - we need to wait for doc to be created...
					return new Error(e)
				}
		}),
  	signIn ({commit, dispatch}, payload){
			console.log('user signing in:', payload)
  		return new Promise(async(resolve, reject) => {

				let result
				if(payload.type === 'google'){
					
					try {
						result = await firebase.auth().signInWithPopup(googleProvider)
						
					} catch (e) {
						console.log(e)
						return reject(e)
					}
					
				}

				if(payload.type === 'email') {

					try {

						if(payload.signup){
							// user is signing up
							result = await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)

						} else {
							// user is logging in
							result = await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
						}

					} catch (e) {
						console.log(e)
						return reject(e)
					}
				}

				// check if user is new, add a record if they are
				if (result.additionalUserInfo.isNewUser) {

					let newUserData = {
						email: result.user.email,
						receiveEmailsFor: {
								inactiveHostels: true
						},
						onboardingToShow: {
								admin: true 
						}
					}
					await db.collection(`users`).doc(`${result.user.uid}`).set(newUserData)
				}

				// check if user is admin
				let tokenResult = await firebase.auth().currentUser.getIdTokenResult()
				let isAdmin = tokenResult.claims.admin
				if(isAdmin){
					result.user.isAdmin = true
				}

				
				dispatch('getRemoteUserConfig', result.user)
				commit('setUser', result.user)
				commit('setAuthChecked', true)
				resolve(result)
				
  		})
  	},
  	signOut ({commit}){
  		return new Promise((resolve, reject)=>{
  			firebase.auth().signOut()
  			  .then(function() {
  			    // Sign-out successful.
  			    resolve("signout Successful");
						commit('setUser', null);
						commit('setAccounts', [])
						commit('setSelectedAccount', null)
  			  })
  			  .catch(function(error) {
  			    // An error happened
  			    reject(error)
  			  });
  		})
  		
  	},
  	authChecked ({commit}){
  		commit('setAuthChecked', true);
  	},
  	accountsChecked ({commit}){
  		commit('setAccountsChecked', true);
		},
		selectAccount ({commit, dispatch}, payload){
			console.log('selecting account:', payload)
			commit('setSelectedAccount', payload);
			commit('clearWidgets')
			// only bind if we have an account, sometimes it's null
			if(payload) {
				dispatch('bindSelectedAccount', payload)
				dispatch('bindWidgetDirectory', payload)
			}
		},
		// async getDirectories ({commit}, payload) {
			
		// 	// for a given account id, get the directort documents and set them in store
		// 	console.log('getting directories for:', payload)

		// 	let directoryCollection = await db.collection('accounts')
		// 		.doc(payload.id)
		// 		.collection('directories')
		// 		.get()

		// 	let directories = {}

		// 	for (let index = 0; index < directoryCollection.docs.length; index++) {
		// 		let document = directoryCollection.docs[index]
		// 		let directory = document.data();
		// 		directories[document.id] = directory
		// 	}

		// 	console.log('directories:', directories);
			
		// 	commit('setDirectories', directories);

		// },
		bindSelectedAccount: firestoreAction(async ({ bindFirestoreRef }, payload) => {
				// return the promise returned by `bindFirestoreRef`
				console.log('binding to an account, payload:', payload)
				try {
					let bound = await bindFirestoreRef('selectedAccount', db.collection('accounts').doc(payload.id));
					return bound
				} catch(e){
					console.log('binding to selected account error:', e)
					// try again - we need to wait for doc to be created...
					return new Error(e)
				}
		}),
		bindWidgetDirectory: firestoreAction(async ({ bindFirestoreRef }, payload) => {
				// return the promise returned by `bindFirestoreRef`
				console.log('binding to directory, payload:', payload)
				try {
					let bound = await bindFirestoreRef('directories.widgetDirectory', db.collection('accounts').doc(payload.id).collection('directories').doc('widgetDirectory'));
					return bound
				} catch(e){
					console.log('binding error:', e)
					// try again - we need to wait for doc to be created...
					return new Error(e)
				}
		}),
  	setLoggedInUser ({commit}, payload) {
			console.log("setting logged in user:", payload)
			return new Promise(async (resolve, reject) => {
				try {
					commit('setUser', payload);
					resolve()
				} catch (e) {
					reject(e)
				}
				
			})
		},
		archiveSite({commit, state}, payload){
			// need to call this function when the user archives a site
			return new Promise((resolve, reject) => {
				try {
					console.log("store: archiving site:", payload);
					let idToRemove = payload;
					let accounts = state.accounts.filter(site => {
						return site.id !== idToRemove
					})
					console.log("store: accounts is now:", accounts)
					commit('setAccounts', accounts)
					resolve()
				} catch (e) {
					console.log('store: error archiving site:', e)
					reject(e)
				}
			}) 
		},
		getAccount({commit, dispatch}, payload) {

			// accepts an account ID as a payload
			// try to fetch it from the DB and add it to the accounts array in store
			return new Promise(async(resolve, reject) => {
				
				try	{
					let doc = await db.collection('accounts')
						.doc(payload)
						.get()

					let data = doc.data()
					data.id = doc.id

					let accounts = this.state.accounts;

					accounts.push(data)

					commit('setAccounts', accounts)

					resolve(data)
	
				} catch (e) {
					console.log("err getting account:", e)
					reject(false)
				}

			})

		},
		getUsers({ commit }, payload) {
			console.log("getting users, payload:", payload);
			return new Promise(async (resolve, reject) => {

				let usersToAdd = [];

				console.log('state.users.length:', this.state.users.length);

				let usersQuery = db.collection('usersSecure')
					.orderBy('createdDate', 'desc')
					.startAt(this.state.users.length > 0 ? this.state.users.length -1 : 100000000000000)
					.limit(20)

				let users = await usersQuery.get()

				console.log('users:', users);
				
				if(!users.empty){

					for (let index = 0; index < users.docs.length; index++) {
						let document = users.docs[index]
						let user = document.data();
						user.id = document.id;

						usersToAdd.push(user);	
					}

				} else {
					console.log("users query returned 0 docs")
				}
				
				commit("setUsers", unionBy(this.state.users, usersToAdd, "id"))

				resolve()
			
			})
		},
		getUser({commit}, payload) {
			console.log("getting user:", payload)

			return new Promise(async (resolve, reject) => {

				try	{
					let doc = await db.collection('users')
						.doc(payload)
						.get()

					let data = doc.data()
					data.id = doc.id

					resolve(data)
	
				} catch (e) {
					console.log("err getting user:", e)
					reject(false)
				}
			})
		},
		getSecureUser({commit}, payload) {
			console.log("getting secure user:", payload)

			return new Promise(async (resolve, reject) => {

				try	{
					let doc = await db.collection('usersSecure')
						.doc(payload)
						.get()

					let data = doc.data()
					data.id = doc.id

					resolve(data)
	
				} catch (e) {
					console.log("err getting user:", e)
					reject(false)
				}
			})
		},
		getWidgets({commit, dispatch}, payload) {

			// this function can get any type of widget and add them to the store
			console.log('getting widgets:', payload)
			console.log('this.state.selectedAccount.id:', this.state.selectedAccount.id);
			
			let collectionArray = ["hwWidgets", "hwPowered", "hwSearch", "hwMap" ]

			// if no selectedAccount, return
			return new Promise(async (resolve, reject) => {
				
				if(collectionArray.indexOf(payload.type) < 0 ) { reject("Incorrect widget type supplied")}

				try {

					let query;

					console.log('this.state.widgets:', this.state.widgets);

					// filter out archived widgets, can happen if user deleted a widget and didn't refresh
					let activeWidgets = this.state.widgets[payload.type].filter(w => !w.archived)					

					if(activeWidgets.length){

						let lastWidget = this.state.widgets[payload.type][this.state.widgets[payload.type].length-1]

						console.log('lastWidget:', lastWidget);

						query =  db.collection("accounts")
							.doc(this.state.selectedAccount.id)
							.collection(payload.type)
							.withConverter(helpers[`${payload.type}Converter`])
							.orderBy('createdAt', 'desc')
							.startAfter(lastWidget.createdAt)
							.limit(10)
					} else {
						query =  db.collection("accounts")
							.doc(this.state.selectedAccount.id)
							.collection(payload.type)
							.withConverter(helpers[`${payload.type}Converter`])
							.orderBy('createdAt', 'desc')
							.limit(10)
					}
					
					let widgets = await query.get()
					
					if(widgets.empty){
						console.log("no widgets")
						resolve(false)
						return
					}

					let newWidgets = [];
					widgets.docs.forEach(doc => {
						newWidgets.push(doc.data())
					})
	
					let widgetCountBefore = this.state.widgets[payload.type]['length']
					
					let combinedWidgets = unionBy(this.state.widgets[payload.type], newWidgets, "id")

					
					console.log('updated widgets list:', combinedWidgets );
					
					commit('setWidgets', {
						type: payload.type,
						docs: combinedWidgets
					})
					
					let widgetCountAfter = this.state.widgets[payload.type]['length']
					console.log('widgetCountBefore:', widgetCountBefore);
					console.log('widgetCountAfter:', widgetCountAfter);

					if(widgetCountAfter < widgetCountBefore
						|| widgetCountAfter - widgetCountBefore < 10) {
							// we've got the last of the list
							resolve(false)
						} else {
						// list was updated and there's more to come
						resolve(true)
					}

				} catch (error) {
					console.log("getWidgets error:", error)
					reject(error)
				}

			})

		},
		updateWidgetInStore({commit, dispatch}, payload) {
			console.log("updating widget in store, payload:", payload);

			return new Promise (async (resolve, reject) => {

				let combinedWidgets;
				
				if(payload.action === "add") {
					// adding a widget to existing widgets
					combinedWidgets = unionBy(this.state.widgets[payload.type], [payload.widget], "id")
				}
	
				// commit updates
				commit('setWidgets', {
					type: payload.type,
					docs: combinedWidgets
				})

				resolve()
			})

		},
		getAccounts({ commit, dispatch }, payload) {
  		
			console.log("getting User Accounts:", payload);

  		return new Promise(async (resolve, reject) => {

				let accountsToAdd = [];
				let query;
				if(payload.isAdmin){

					query = db.collection('accounts')
					.where("archived", "!=", true)
					.limit(100)

				} else {
					
					query = db.collection(`accounts`)
					.where("teammates", "array-contains", payload.uid)
					.where("archived", "!=", true)
				}

				let collection = await query.get()

				if(!collection.empty){
					// WE NEED TO UPDATE THIS LATER TO SHOW THE ACOUNTS THE USER HAS ACCESS TO
					for (let index = 0; index < collection.docs.length; index++) {
						let document = collection.docs[index]
						let account = document.data();
						account.id = document.id;

						// skip archived accounts
						if(account.archived) continue;

						accountsToAdd.push(account);	

					}

				}

				let accounts = unionBy(this.state.accounts, accountsToAdd, "id")
				console.log('setting accounts:', accounts);

				commit('setAccounts', accounts)
				resolve()

		})
		},
		async getRemoteUserConfig({commit}, payload){
			let remoteUserConfig = await remoteConfig.init(payload)
			commit('setRemoteUserConfig', remoteUserConfig)
		}
	},
  getters: {
  	user(state){
  		return state.user
		},
		userData(state){
			return state.userData
		},
		accounts(state){
			return state.accounts
		},
  	authChecked(state){
  		return state.authChecked
		},
		directoriesByList(state){
			let typeTextKeys = {
				"hwSearch": "HW Search Widget",
				"hwWidgets": "HW List Widget",
				"hwMap": "HW Map Widget",
				"Page": "Page"
			}
			const objArray = [];
			Object.keys(state.directories).forEach(key => {
				Object.keys(state.directories[key]).forEach(subKey => {
					Object.keys(state.directories[key][subKey]).forEach(subKey2 => {
						// console.log('state.directories[key][subKey][subKey2]:', state.directories[key][subKey][subKey2]);
						objArray.push({
							id: state.directories[key][subKey][subKey2]['id'],
							url: state.directories[key][subKey][subKey2]['url'],
							title: state.directories[key][subKey][subKey2]['title'],
							collection: state.directories[key][subKey][subKey2]['collection'],
							typeText: typeTextKeys[state.directories[key][subKey][subKey2]['collection']] 
						})
					})
				})	
			});
			objArray.push({
				// id: "home",
				title: "Overview",
				collection: "Page",
				route: "/"
			})

			return objArray
		},
		remoteUserConfig(state){
			return state.remoteUserConfig
		}
  }
})