// takes:
// {
//   "confirmFunction": Function,
//   "tite": String,
//   "body": String,
//   "error": String,
//   "loading": Boolean 
// },

export default {
  name: 'ModalGeneric',
  components: {},
  props: { 
    title: {
      type: String,
      default: "Are you sure?"
    },
    body: {
      type: String,
      default: "This cannot be undone..."
    }, 
    links: {
      type: Array,
      default: []
    },
    error: {
      type: String,
      default: null
    }, 
    confirmFunction: {
      type: Function,
      default: function(){
        return alert("default confirm function...")
      }
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    confirmText: {
      type: String,
      default: "Confirm"
    }
  },
  data () {
    return {  
      view: {
        'loading': false
      }
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}