export default {
  name: 'modal-feedback',
  components: {},
  props: {
    formUrl: {
      type: String,
      default: "https://docs.google.com/forms/d/e/1FAIpQLSfmyZGpgXuujtjLTji3gd99cHIuErohIJtTwK7sbt-J_FUOqg/viewform?embedded=true"
    },
  },
  data () {
    return {
      view: {
        
      }
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
