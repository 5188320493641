import Fuse from 'fuse.js'

export default {
  name: 'search-modal',
  components: {

  },
  props: [],
  data () {
    return {
      searchInput: "",
      results: [],
      selectedResult: null
    }
  },
  computed: {
    directoriesList () {
      return this.$store.getters.directoriesByList
    },
    directories () {
      return this.$store.state.directories
    },
    selectedAccount: function(){
      return this.$store.state.selectedAccount
    },
    escPressed () {
      return this.$store.state.escPressed
    }
  },
  watch: {
    $route () {
      this.$emit('close');
    },
    escPressed () {
      this.$emit('close')
    }
  },
  mounted () {
    this.$refs["searchBox"].focus();
    console.log("searchModal mounted, directories:", this.directoriesList)
  },
  methods: {
    close () {
      this.$emit('close');
    },
    filterResults () {
      this.selectedResult = -1;
      console.log('filtering results')
      const options = {
        includeScore: true,
        keys: ['title', 'url']
      }

      const fuse = new Fuse(this.directoriesList, options)

      this.results = fuse.search(this.searchInput)
    },
    boldText(text){
      return text.replaceAll(this.searchInput, '<b>' + this.searchInput + '</b>');
    },
    getLink(suggestion) {
      let widgetType;
      if(suggestion.collection === "hwSearch") {
        widgetType = "hw-search-widgets"
        return `/${this.selectedAccount.id}/${widgetType}/${suggestion.id}`
      }
      if(suggestion.collection === "hwWidgets"){
        widgetType = "hw-widgets"
        return `/${this.selectedAccount.id}/${widgetType}/${suggestion.id}`
      }
      if(suggestion.collection === "Page") {
        return `/${this.selectedAccount.id}${suggestion.route}`
      }
    },
    arrowDown(){
      console.log("arrow down")
      this.selectResult(1)
    },
    arrowUp(){
      console.log("arrow up")

      this.selectResult(-1)
    },
    selectResult(increment){
      
      // if we've no links focused, and user presses up, return
      if(this.selectedResult === -1 && increment === -1) return

      // if we're at the bottom of the list, return
      if(increment === 1 && (this.selectedResult+1 >= this.results.length)) return

      
      if(increment === -1){
        this.selectedResult--
      } else {
        this.selectedResult++
      }
      
      // if we're at the top of the list, select the search input
      if(this.selectedResult === -1) return this.$refs["searchBox"].select() ;

      console.log('focusing result:', this.selectedResult)

      let el = this.$refs[`result-${this.selectedResult}`][0]['$el']
      // console.log('el:', el);
      
      el.focus();
    }
  }
}
