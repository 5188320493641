import firebase from 'firebase/app'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// init myConfig
Vue.prototype.$myConfig = {
  domain: process.env.VUE_APP_Domain,
  remote: null
}

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

// simple analytics
import SimpleAnalytics from "simple-analytics-vue";
Vue.use(SimpleAnalytics, 
  { 
    skip: process.env.NODE_ENV !== "production",
    domain: "api.referextra.com"
  }
);

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

// Import Components
import Loader from '@/components/loader'
import myButton from '@/components/button'
import BetaTag from '@/components/beta-tag'
import Navigation from '@/components/navigation'
import SideNav from '@/components/sideNav'
import ModalGeneric from '@/components/ModalGeneric'
import ModalStripe from '@/components/modal-stripe'
import ModalFeedback from '@/components/modal-feedback'
import onboardingVideo from '@/components/onboardingVideo'
import VueJsonPretty from 'vue-json-pretty'
import Keypress from 'vue-keypress'
import VShowSlide from 'v-show-slide'
import 'vue-json-pretty/lib/styles.css'
import 'leaflet/dist/leaflet.css';

// register components
Vue.component('Loader', Loader)
Vue.component('Button', myButton)
Vue.component('BetaTag', BetaTag)
Vue.component('Navigation', Navigation)
Vue.component('SideNav', SideNav)
Vue.component('ModalGeneric', ModalGeneric)
Vue.component('ModalStripe', ModalStripe)
Vue.component('ModalFeedback', ModalFeedback)
Vue.component('onboardingVideo', onboardingVideo)
Vue.component('Keypress', Keypress)
Vue.component('VueJsonPretty', VueJsonPretty)
Vue.use(VShowSlide)

// if we're not in development, AND 'logs' is not in the URL
if (process.env.NODE_ENV !== 'development' && !(window.location.href.search("logs") >=0 )) {
  // Disable console logs in production
  console.log(`I don't want no logs, a log is a guy that can't get no love from me...`);
  
  if (window) {
    window.console.log = function () { };
  }
}

// filters
// https://date-fns.org/v2.9.0/docs/Getting-Started
import { formatDistanceToNow, format } from 'date-fns'
Vue.filter('formatDateToNow', function(value) {
  if (value) {
    return formatDistanceToNow(value, { addSuffix: true })
  }
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return format(value, 'd/MMM/yyyy')
  }
})

// Fontawesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faCaretDown,
  faArrowLeft,
  faChevronRight,
  faChevronDown,
  faExternalLinkAlt,
  faExclamationTriangle,
  faMinusCircle,
  faPlus,
  faTrash,
  faVideo,
  faTimes,  
  faComments,
  faBars,
  faEllipsisH
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCaretDown, faExternalLinkAlt, faArrowLeft, faChevronRight, faChevronDown, faExclamationTriangle, faMinusCircle, faPlus, faTrash, faVideo, faTimes, faComments, faBars, faEllipsisH)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import '@/assets/css/styles.scss'

const isProd = process.env.NODE_ENV === 'production';

import 'firebase/firestore';
import "firebase/performance"; // beta performance library from firebase
import "firebase/analytics"

let firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId
}

// console.log('firebaseConfig:', firebaseConfig);

let app = null;
if (!firebase.apps.length) {
  app = firebase.initializeApp(firebaseConfig);

  // if(process.env.NODE_ENV==='development'){
  //   self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  // }

  // const appCheck = firebase.appCheck();
  // // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // // key is the counterpart to the secret key you set in the Firebase console.
  // appCheck.activate(
  //   process.env.VUE_APP_reCAPTCHA_KEY,

  //   // Optional argument. If true, the SDK automatically refreshes App Check tokens as needed.
  //   true);
} else {
  app = firebase.apps[0]
}

// call performance, analytics and remoteConfig
app.performance();
app.analytics();
// const remoteConfig = firebase.remoteConfig();
// remoteConfig.settings.minimumFetchIntervalMillis = 1000;

export const db = app.firestore();
export const auth = app.auth();

let unsubscribe = firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    // User is signed in.
    // console.log('we have a user:',user);
    // check for admin priveleges
    let tokenResult = await firebase.auth().currentUser.getIdTokenResult()
    let isAdmin = tokenResult.claims.admin
    if(isAdmin){
      user.isAdmin = true
    }
    await store.dispatch('setLoggedInUser', user)
    await store.dispatch("bindUserData", user.uid);
    store.dispatch("getAccounts", user);
    store.dispatch("getRemoteUserConfig", user);
    startApp()
  } else {
    // No user is signed in.
    console.log('no user')
    // tell store that auth is checked
    store.dispatch('authChecked');
    startApp()
  }
  // unsubscribe from auth change
	await unsubscribe()
});

// Import the CSS or use your own!
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  // You can set your default options here
  position: POSITION.BOTTOM_RIGHT,
  transition: "Vue-Toastification__fade"
};

Vue.use(Toast, options);

const startApp = function(){
  console.log('starting app')
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}