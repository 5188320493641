<template>
  <div id="app" class="max-w-6xl m-auto">

    <Keypress 
      key-event="keydown" 
      :keyCode="27"
      @success="closeModal" 
    />

    <Navigation />

    <div class="flex">

      <!-- Side Nav Malarkey -->
      <div data-test="me">

        <div class="sm:hidden icon absolute sideNav-toggle flex items-center justify-center" @click="view.mobileSideNav = !view.mobileSideNav">
          <font-awesome-icon class="ml-2" icon="bars" />
        </div>
        <div class="h-100 sideNav-holder bg-back animated" style="width:18rem" :class="{'open': view.mobileSideNav}" v-if="user">
          <SideNav />
        </div>
      </div>

      <!-- Main content -->
      <div class="sideNav-bg sm:hidden animated" :class="{'block': view.mobileSideNav}" @click="view.mobileSideNav = false"></div>
      <div class="m-3 w-full pb-32" style="min-height:70vh">
        <router-view/>
      </div>

    </div>

  </div>
</template>

<script>


export default {
  name: 'app',
  computed: {
    user: function(){
      return this.$store.state.user
    }
  },
  data: function () {
    return {
      view: {
        mobileSideNav: false
      }
    }
  },
  watch: {
    $route () {
      this.view.mobileSideNav = false;
    }
  },
  mounted () {
    
  },
  methods: {
    closeModal () {
      // set ESC pressed in store using a time (for ease)
      this.$store.dispatch("updateEscPressed")
    }
  }
}
</script>

<style>

  .sideNav-bg {
    z-index:1010;
    width:100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: none;  
    @apply bg-black opacity-75 fixed;
  }

  .sideNav-toggle {
    top:10px;
    left:5px;
    width:40px;
    height:40px
  }

  .sideNav-holder {
    position: sticky;
    top: 0px;
  }

  @media (max-width: 640px) {

    .sideNav-holder {
      position: fixed;
      height: 100vh;
      left: -18rem;
      z-index: 1021;
    }
    .sideNav-holder.open {
      transform: translateX(18rem);
    }

    .sideNav-holder.open .sideNav-bg {
      display: initial;
    }
  }
</style>
