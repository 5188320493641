export default {
  name: 'loader',
  components: {},
  props: {
    size: {
      type: String,
      default: "40"
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
