export default {
  name: 'beta-tag',
  components: {},
  props: {
    type: {
      required: false,
      default: "word"
    },
    formUrl: {
      required: false
    }
  },
  data () {
    return {
      view: {
        betaModal: false,
        feedbackModal: false
      }
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    betaClicked () {
      console.log("beta clicked")
      this.view.betaModal = true;
    },
    closeModal () {
      this.view.betaModal = false
    }
  }
}
