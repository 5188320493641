function generateID () {
	var ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

	var ID_LENGTH = 12;

	var rtn = '';
	for (var i = 0; i < ID_LENGTH; i++) {
	  rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
	}
	return rtn;
}

let hwSearchConverter = {
  toFirestore: function(searchWidget) {
    return {
      title: searchWidget.title,
      id: searchWidget.id,
      createdAt: searchWidget.createdAt,
      updatedAt: searchWidget.updatedAt,
      config: searchWidget.config,
      url: searchWidget.url
    };
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    return new SearchWidget(snapshot.id, data)
  }
};

class SearchWidget {
  constructor (id, data) {
    if(!data) data = {}
    this.title = data.title || "My Search Widget"
    this.id = id || generateID()
    this.createdAt = data.createdAt || new Date().getTime()
    this.updatedAt = data.updatedAt || new Date().getTime()
    this.config = data.config || {
      destination: null,
      PHPubRef: "",
      PHCamRef: "",
      widgetMode: "hwSearch"
    }
    this.url = data.url || null
  }
}

let hwMapConverter = {
  toFirestore: function(mapWidget) {
    return {
      title: mapWidget.title,
      id: mapWidget.id,
      createdAt: mapWidget.createdAt,
      updatedAt: mapWidget.updatedAt,
      config: mapWidget.config,
      url: mapWidget.url
    };
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    return new MapWidget(snapshot.id, data)
  }
};

class MapWidget {
  constructor (id, data) {
    if(!data) data = {}
    this.title = data.title || "New Map Widget"
    this.id = id || generateID()
    this.createdAt = data.createdAt || new Date().getTime()
    this.updatedAt = data.updatedAt || new Date().getTime()
    this.config = data.config || {
      destination: null,
      mapSettings: {
        tileUrl: 'https://api.maptiler.com/maps/voyager/{z}/{x}/{y}.png?key=Alaj4EzqYElK7WPsYh4I',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 2,
        center: [0,0],
        options: {

        }
      },
      hostels: [],
      PHPubRef: "",
      PHCamRef: "",
      widgetMode: "hwMap"
    }
    this.url = data.url || null
  }
}

let hwWidgetsConverter = {
  toFirestore: function(widget) {
    return {
      title: widget.title,
      id: widget.id,
      createdAt: widget.createdAt,
      updatedAt: widget.updatedAt,
      tcConfig: widget.tcConfig,
      url: widget.url
    };
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    return new CustomHwWidget(snapshot.id, data)
  }
};

class CustomHwWidget {
  constructor (id, data) {
    if(!data) data = {}
    this.title = data.title || "My Hostelworld Widget"
    this.id = id || generateID()
    this.createdAt = data.createdAt || new Date().getTime()
    this.updatedAt = data.updatedAt || new Date().getTime()
    this.tcConfig = data.tcConfig || {
      PHCamRef: "",
      PHPubRef: "",
      hostels: [],
      widgetMode: 'accommodation'
    }
    this.url = data.url || null
  }
}


export default {
  hwSearchConverter: hwSearchConverter,
  SearchWidget: SearchWidget,
  CustomHwWidget: CustomHwWidget,
  hwWidgetsConverter: hwWidgetsConverter,
  MapWidget: MapWidget,
  hwMapConverter: hwMapConverter
}

