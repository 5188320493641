import themeToggle from '@/components/themeToggle'
import searchModal from '@/components/searchModal'
import { db } from '@/main'

export default {
	name: 'Navigation',
	components: {
		themeToggle,
		searchModal
	},
	watch: {
		$route () {
			this.view.userDropdown = false;
		}
	},
	computed: {
		user () {
			return this.$store.state.user
		},
		userData () {
			return this.$store.state.userData
		}
	},
	mounted() {
		console.log('navigation mounted, user:', this.user)
	},
	data: function (){
		return {
			view: {
				'userDropdown': false,
				"menu": false,
				"searchModal": false
			}
		}
	},
	methods: {
		toggleJson () {
			this.userData.adminConfig.viewJSON = !this.userData.adminConfig.viewJSON
			this.saveAdminConfig()
		},
		toggleDropdown: function() {
			console.log('user profile clicked');
			this.view.userDropdown = !this.view.userDropdown
		},
		signOut: function() {
			this.$store.dispatch('signOut')
				.then(data=>{
					console.log('data:', data);
					this.$router.push('/login')
				})
				.catch(e=>{
					console.log("error signing out:", e)
					alert("err signing out")
				})
		},
		toggleMenu () {
			console.log('user is toggling menu')
			this.view.menu = !this.view.menu
		},
		openModal () {
			console.log('opening modal')
			this.view.searchModal=true;
		},
		saveAdminConfig () {
			console.log("saving admin config, this.user.uid:", this.user.uid)

			try {
				db.collection("users").doc(this.user.uid).update({
						'adminConfig': this.userData.adminConfig,
						"updatedAt": new Date().getTime()
				})
				console.log("user admin config saved")				
			} catch (error) {
				console.log("error saving user config:", error)
			}
		}
	}
}