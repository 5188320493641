import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import store from './store'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: "/app/",
  routes: [
    {
      path: '/admin',
      name: 'admin',
      component: () => import(/* webpackChunkName: "admin" */ './views/Admin'),
      meta: {
        public: false,
        title: 'Referextra - Your missing link',
        requiresOrg: false,
        requiresAdmin: true
      }
    },
    {
      path: '/admin/users',
      name: 'users',
      component: () => import(/* webpackChunkName: "users" */ './views/users'),
      meta: {
        public: false,
        title: 'Users',
        requiresOrg: false,
        requiresAdmin: true
      }
    },
    {
      path: '/admin/users/:user',
      name: 'users',
      component: () => import(/* webpackChunkName: "users" */ './views/user'),
      meta: {
        public: false,
        title: 'User',
        requiresOrg: false,
        requiresAdmin: true
      }
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        public: true,
        title: 'Referextra - Your missing link',
        requiresOrg: false
      }
    },
    {
      path: '/login',
      name: 'login',
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "login" */ './views/login'),
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
        requiresOrg: false,
        title: 'Referextra - login'
      }
    },
    {
      path: '/signup',
      name: 'signup',
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "login" */ './views/signup'),
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
        requiresOrg: false,
        title: 'Referextra - signup'
      }
    },
    {
      path: '/account',
      name: 'account',
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "account" */ './views/account'),
      meta: {
        public: false,
        requiresOrg: false,
        onlyWhenLoggedOut: false,
        title: 'Your Account'
      }
    },
    {
      path: '/invite',
      name: 'invite',
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "invite" */ './views/invite'),
      meta: {
        public: true,
        onlyWhenLoggedOut: false,
        requiresOrg: false,
        title: 'Referextra - invite'
      }
    },
    {
      path: '/:site',
      name: 'overview',
      meta: {
        public: false,
        requiresOrg: true,
        onlyWhenLoggedOut: false,
        title: 'Referextra | Overview'
      },
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "overview" */ './views/overview')
    },
    {
      path: '/:site/overview',
      name: 'overview',
      meta: {
        public: false,
        requiresOrg: true,
        onlyWhenLoggedOut: false,
        title: 'Referextra | Overview'
      },
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "overview" */ './views/overview')
    },
    {
      path: '/:site/hw-widgets',
      name: 'hostelworldWidgets',
      meta: {
        public: false,
        requiresOrg: true,
        onlyWhenLoggedOut: false,
        title: 'Referextra | Hostelworld widgets'
      },
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "hw-custom-widgets" */ './views/customWidgets')
    },
    {
      path: '/:site/hw-widgets/:widget',
      name: 'widget',
      meta: {
        public: false,
        requiresOrg: true,
        onlyWhenLoggedOut: false,
        title: 'Referextra | Manage Widgets'
      },
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "hw-custom-widgets" */ './views/widget')
    },
    {
      path: '/:site/hw-powered-widgets',
      name: 'hostelworldPoweredWidgets',
      meta: {
        public: false,
        requiresOrg: true,
        onlyWhenLoggedOut: false,
        title: 'Referextra | Hostelworld Powered Widgets'
      },
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "hw-powered-widgets" */ './views/hw-powered-widgets')
    },
    {
      path: '/:site/hw-search-widgets',
      name: 'hostelworldSearchWidgets',
      meta: {
        public: false,
        requiresOrg: true,
        onlyWhenLoggedOut: false,
        title: 'Referextra | Hostelworld Search widgets'
      },
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "hw-search-widgets" */ './views/hw-search-widgets')
    },
    {
      path: '/:site/hw-search-widgets/:widget',
      name: 'hw-search-widget',
      meta: {
        public: false,
        requiresOrg: true,
        onlyWhenLoggedOut: false,
        title: 'Hostelworld Search Widget'
      },
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "hw-search-widgets" */ './views/hw-search-widget')
    },
    {
      path: '/:site/hw-map-widgets',
      name: 'hostelworldMapWidgets',
      meta: {
        public: false,
        requiresOrg: true,
        onlyWhenLoggedOut: false,
        title: 'Referextra | Hostelworld Search widgets'
      },
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "hw-map-widgets" */ './views/hw-map-widgets')
    },
    {
      path: '/:site/hw-map-widgets/:widget',
      name: 'hw-map-widget',
      meta: {
        public: false,
        requiresOrg: true,
        onlyWhenLoggedOut: false,
        title: 'Hostelworld Map Widget'
      },
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "hw-map-widgets" */ './views/hw-map-widget')
    },
    {
      path: '/:site/settings',
      name: 'sitesettings',
      meta: {
        public: false,
        requiresOrg: true,
        onlyWhenLoggedOut: false,
        title: 'Referextra | Manage Site'
      },
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "site-settings" */ './views/sitesettings')
    },
    
    { 
      path: '*', 
      component: () => import(/* webpackChunkName: "notfound" */ './views/notFound')
    }
  ]
})


router.beforeEach(async (to, from, next) => {

  // update page title
  document.title = to.meta.title || 'Referextra'

  console.log("to:", to)

  // guard against restricted routes
  const isPublic = to.matched.some(record => record.meta.public)
  const requiresOrg = to.matched.some(record => record.meta.requiresOrg)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!store.state.user;
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)

  console.log('requiresAdmin:', requiresAdmin);
  console.log('loggedIn:', loggedIn);
  
  if (!isPublic && !loggedIn) {
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    });
  }
  
  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  
  if (loggedIn && requiresAdmin && !store.state.user.isAdmin ) {
    // if user is not admin, bounce them to the start page
    return next('/')
  }

  // check if route is not public (needs account) and if we have a selectedAccount
  if(
    requiresOrg
    && (requiresOrg && !store.state.selectedAccount
    || store.state.selectedAccount && (to.params.site !== store.state.selectedAccount.id))
    ){
    console.log("detected that route is not public, and we don't have a selected Account")
    let accounts = store.state.accounts
    console.log('accounts:', accounts);
    let matchedAccount = accounts.filter(account => account.id === to.params.site)[0];

    console.log('matchedAccount:', matchedAccount);
    

    if(matchedAccount){
      await store.dispatch("selectAccount", matchedAccount)  
    } else {
      
      try {
        
        // try get the account
        let accountData = await store.dispatch("getAccount", to.params.site) 
  
        await store.dispatch("selectAccount", accountData)  

      } catch (e) {
        // error is logged in store file
        return next('/')
      }
    }
  }


  next();
})


export default router
