// takes:
// {
//   "confirmFunction": Function,
//   "tite": String,
//   "body": String,
//   "error": String,
//   "loading": Boolean 
// },
import {db} from '@/main'
// eslint-disable-next-line
let stripe = Stripe(`${process.env.VUE_APP_stripe_publishable}`),
  elements = stripe.elements(),
  card = undefined;

export default {
  name: 'ModalStripe',
  components: {},
  props: { 
    site: {
      type: String,
    }
  },
  data () {
    return {  
      view: {
        'loading': false,
        'loadingPlans': false,
        'accountUpgraded': false
      },
      error: null,
      secureUser: null,
      plans: [],
      selectedPlan: null
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  watch: {
    $route () {
      this.$emit('close');
    },
    escPressed () {
      this.$emit('close')
    }
  },
  mounted () {
    card = elements.create('card');
    card.mount(this.$refs.card);
    card.update({
      hidePostalCode: true
    })

    this.$bind('secureUser', db.collection("usersSecure").doc(this.user.uid))
    .then(() => {
        console.log('bound secureUser:', this.secureAccount);
    })

    this.getPlans()
  },
  beforeDestroy () {
    card.destroy(this.$refs.card);
  },
  methods: {
    formatPlanPrice(stripeAmount){
      return (stripeAmount/100).toFixed(2)
    },
    async getPlans(){
      this.view.loadingPlans = true
      // make request to get plans in Stripe
      try {
        let plansRes = await fetch('/api/plans')
        plansRes = await plansRes.json();
        this.plans = plansRes.data.data
  
        console.log('plans:', this.plans);
        this.selectedPlan = this.plans[0]
        
      } catch (e) {
        console.log('error getting plans:', e)
      } finally{
        this.view.loadingPlans = false
      }
      
    },
    async subscribe(){
      console.log('user wants to subscribe');
      this.view.loading = true;
      this.error = null;

      try {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: card,
          billing_details: {
            email: this.user.email,
          },
        })
        console.log('result:', result);
        
        if (result.error) {
          return this.error = result.error.message
        }

        this.stripePaymentMethodHandler(result);
      } catch(e) {
        console.log('error creating payment method:', e)
        this.error = e
        this.view.loading = false
      }
    },
    async stripePaymentMethodHandler(result){

      try {

        const cusRes = await fetch('/api/attach-payment', {
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            stripeId: this.secureUser.stripeCustomerId,
            payment_method: result.paymentMethod.id
          }),
        });

        // The customer has been created
        const customer = await cusRes.json();
        if(customer.error) throw new Error(customer.message)
        console.log('customer created:', customer)

        //subscribe the customer to the business plan
        const subRes = await fetch('/api/subscription-start', {
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            stripeId: this.secureUser.stripeCustomerId,
            plan: this.selectedPlan.id,
            site: this.site
          }),
        });

        // The subscription has been started
        const subscription = await subRes.json();
        if(subscription.error) throw new Error(subscription.message)
        
        console.log('subscription started:', subscription)

        // handle if we need 3D secure etc
        const { latest_invoice } = subscription.data; 
        const { payment_intent } = latest_invoice;

        if (payment_intent) {
          const { client_secret, status } = payment_intent;

          if (status === 'requires_action') {
            let result = await stripe.confirmCardPayment(client_secret)
            
            if (result.error) {
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              console.log("confirm card payment error:", result.error)
              this.error = result.error.message
            } else {
              // Show a success message to your customer
              this.view.accountUpgraded = true
            }
            
          } else {
            // No additional information was needed
            // Show a success message to your customer
            this.view.accountUpgraded = true
          }
        }

      } catch(e) {
        console.log("error attaching/subscribing:", e)
        this.error = e
      } finally {
        this.view.loading = false
      }
      
    }
  }
}