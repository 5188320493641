export default {
  name: 'theme-toggle',
  components: {},
  props: [],
  data () {
    return {
      theme: localStorage.getItem("theme")
    }
  },
  computed: {

  },
  mounted () {
    // console.log("themeToggle mounted, theme:", this.theme)
    if(!this.theme){
      // set theme as a default
      document.body.setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "dark");
    }
  },
  methods: {
    toggleTheme () {
      console.log('toggling theme')
      if (this.theme == "dark") {
          this.theme = "light";
          document.body.setAttribute("data-theme", "light");
          localStorage.setItem("theme", "light");
      } else {
          this.theme = "dark";
          document.body.setAttribute("data-theme", "dark");
          localStorage.setItem("theme", "dark");
      }
    }
  }
}
