export default {
  name: 'mybutton',
  components: {},
  props: {
    type: {
      type: String,
      default: "button"
    },
    cssClass: {
      type: String,
      default: "primary"
    },
    phrase: {
      type: String,
      default: "Save"
    },
    loading: {
      type: Boolean,
      default: false
    },
    click: {
      type: Function,
      default: function () {
        console.log("Button clicked")
      }
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
