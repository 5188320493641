
export default {
  name: 'side-nav',
  components: {

  },
  props: [],
  data () {
    return {
      selected: "",
      isAdminRoute: false
    }
  },
  computed: {
    accounts () {
      return this.$store.state.accounts
    },
    storeSelectedAccount () {
      return this.$store.state.selectedAccount
    },
    remoteConfig () {
      return this.$store.state.remoteUserConfig
    },
    showOrgNav () {
      return this.$route.meta.requiresOrg
    },
    selectedAccount: {
      // getter
      get: function () {
        if(this.accounts){
          return this.accounts.find(acc => acc.id === this.storeSelectedAccount.id)
        }
        return null
      },
      // setter
      set: function (account) {
        console.log("DROPDOWN account selected:", account)
        if(this.selected.id === account.id) {return}
        this.$store.dispatch("selectAccount", account)
        this.$router.push(`/${account.id}`)
      }
    },
    user () {
      return this.$store.state.user
    },
    // isAdminRoute () {
    //   return !!this.$route.meta.requiresAdmin
    // }
  },
  watch: {
    $route () {
      console.log("route changed:", this.$route);
      this.isAdminRoute = this.$route.meta.requiresAdmin || false
    }
  },
  mounted () {
    // console.log('this.$route:', this.$route);
  },
  methods: {
    isActive(array){
      let isInRoute = array.indexOf(this.$route.name)
      return isInRoute >= 0 ? true : false
    }
  }
}
