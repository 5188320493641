export default {
  name: 'onboarding-video',
  components: {},
  props: {
    videoData: {
      type: Object
    } 
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
